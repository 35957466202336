import React, { useState, ForwardedRef, AllHTMLAttributes } from 'react'
import Icon from '../../Icon/Icon'
import { Icons } from '../../Icon/constants'

type BaseProps<M extends boolean> = {
  label?: string
  error?: string
  type?: string
  name: string
  multiline?: M
}


type TextFieldProps<Multiline extends boolean> = Multiline extends true
  ? AllHTMLAttributes<HTMLTextAreaElement> & BaseProps<Multiline>
  : AllHTMLAttributes<HTMLInputElement> & BaseProps<Multiline>

type TextWrapperForwardedRef<Multiline extends boolean> = ForwardedRef<
  (Multiline extends true ? HTMLTextAreaElement : HTMLInputElement) | null
>

const TextWrapper = <Multiline extends boolean>(
  props: TextFieldProps<Multiline>,
  ref: TextWrapperForwardedRef<Multiline>,
) => {
  const { className, label, error, type = 'text', multiline, ...rest } = props
  const [inputType, setInputType] = useState(type)

  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'))
  }

  return (
    <div className={`${multiline ? 'textarea' : 'text'}-wrapper ${className ?? ''}`}>
      {multiline && (
        <textarea ref={ref} {...rest as any} id={rest.name} placeholder={rest.placeholder ?? ' '} />
      )}
      {!multiline && (
        <input
          type={inputType}
          ref={ref}
          {...rest as any}
          id={rest.name}
          placeholder={rest.placeholder ?? ' '}
        />
      )}
      {label && (
        <label htmlFor={rest.name} className='text-wrapper-label'>
          {label}
        </label>
      )}
      {type === 'password' && (
        <button
          type='button'
          onClick={togglePasswordVisibility}
          className='toggle-password-visibility'>
          {inputType === 'password' ? (
            <Icon iconName={Icons.EYE_OFF} />
          ) : (
            <Icon iconName={Icons.EYE_EMPTY} />
          )}
        </button>
      )}
      {error && <span className='error'>{error}</span>}
    </div>
  )
}

export default React.forwardRef(TextWrapper)
