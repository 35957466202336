'use client'

import { FunctionComponent, MouseEventHandler, ReactNode, useContext } from 'react'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import BaseLink from 'next/link'
import { useRouter } from 'next/navigation'

type LinkProps = {
  href: string
  children: ReactNode
  className?: string
  title?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  tabIndex?: number
  dataCategory?: string
  ariaLabel?: string
}
const Link: FunctionComponent<LinkProps> = ({
  href,
  children,
  className,
  title,
  onClick,
  tabIndex,
  dataCategory,
  ariaLabel,
}) => {
  const { clearModals } = useContext(ModalContext) as ModalContextType
  const router = useRouter()

  const locale = useCurrentLocale()

  function cleanUrl(url) {
    let newUrl = ''
    switch (true) {
      case url.startsWith('/' + locale + '/'):
        newUrl = url.replace(/^\/[^/]+\//, '')
        break
      case url.startsWith(locale + '/'):
        newUrl = url.replace(/^.+\//, '')
        break

      default:
        newUrl = url.replace(/^\/+/, '')
        break
    }
    return newUrl
  }

  function finalUrl(href) {
    if (href.startsWith('http')) {
      return href
    } else {
      const url = cleanUrl(href) || ''
      const newLink = '/' + locale + '/' + url
      return newLink
    }
  }

  const link = finalUrl(href)

  return (
    // <a
    //   href={link}
    //   className={className}
    //   title={title}
    //   onClick={(e) => {
    //     onClick && onClick(e)
    //     clearModals()
    //   }}
    //   tabIndex={tabIndex}
    //   data-category={dataCategory}
    //   aria-label={ariaLabel}>
    //   {children}
    // </a>
    <BaseLink
      href={link}
      locale={locale}
      className={className}
      title={title}
      prefetch={false}
      // Custom prefetch on hover, like nextjs we check connection type before prefetching
      onMouseEnter={() => {
        const hasFastInternet =
          'connection' in navigator &&
          navigator.connection &&
          typeof navigator.connection === 'object' &&
          'effectiveType' in navigator.connection &&
          navigator.connection.effectiveType === '4g'
        if (hasFastInternet) {
          void router.prefetch(link)
        }
      }}
      onClick={(e) => {
        onClick && onClick(e)
        clearModals()
      }}
      tabIndex={tabIndex}
      data-category={dataCategory}
      passHref
      aria-label={ariaLabel}>
      {children}
    </BaseLink>
  )
}

export default Link
